import React, { createContext, useContext, useState, useEffect } from 'react';

interface ConsentState {
  isCookieBannerShow:boolean;
  necessary: boolean;
  performance: boolean;
  targeting:boolean,
  unclassified:boolean,
}

interface ConsentContextType {
  consent: ConsentState;
  setConsent: React.Dispatch<React.SetStateAction<ConsentState>>;
}

const defaultConsent: ConsentState = {
  isCookieBannerShow:true,
  necessary: true,
  performance: true,
  targeting:true,
  unclassified:true,
};

const ConsentContext = createContext<ConsentContextType | undefined>(undefined);

export const ConsentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [consent, setConsentState] = useState<ConsentState>(defaultConsent);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const cookieBannerShown = localStorage.getItem('cookieBannerShown');

      setConsentState(prevConsent => ({
        ...prevConsent,
        isCookieBannerShow: cookieBannerShown !== 'true',
      }));
    
      const savedConsent = localStorage.getItem('cookieConsent');
      if (savedConsent) {
        setConsentState(JSON.parse(savedConsent));
      }
    }
  }, []);

  const setConsent: React.Dispatch<React.SetStateAction<ConsentState>> = newState => {
    setConsentState(prevState => {
      const updatedState = typeof newState === 'function' ? newState(prevState) : newState;
      if (typeof window !== 'undefined') {
        localStorage.setItem('cookieConsent', JSON.stringify(updatedState));
      }
      return updatedState;
    });
  };

  return (
    <ConsentContext.Provider value={{ consent, setConsent }}>
      {children}
    </ConsentContext.Provider>
  );
};

export const useConsent = (): ConsentContextType => {
  const context = useContext(ConsentContext);
  if (!context) {
    throw new Error('useConsent must be used within a ConsentProvider');
  }
  return context;
};