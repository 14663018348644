exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-[id]-tsx": () => import("./../../../src/pages/blog/[id].tsx" /* webpackChunkName: "component---src-pages-blog-[id]-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-ehr-connections-tsx": () => import("./../../../src/pages/ehr-connections.tsx" /* webpackChunkName: "component---src-pages-ehr-connections-tsx" */),
  "component---src-pages-events-[id]-tsx": () => import("./../../../src/pages/events/[id].tsx" /* webpackChunkName: "component---src-pages-events-[id]-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-frequently-asked-questions-tsx": () => import("./../../../src/pages/frequently-asked-questions.tsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lp-ai-medical-coding-solution-tsx": () => import("./../../../src/pages/lp/ai-medical-coding-solution.tsx" /* webpackChunkName: "component---src-pages-lp-ai-medical-coding-solution-tsx" */),
  "component---src-pages-lp-bing-ai-medical-coding-solution-tsx": () => import("./../../../src/pages/lp/bing/ai-medical-coding-solution.tsx" /* webpackChunkName: "component---src-pages-lp-bing-ai-medical-coding-solution-tsx" */),
  "component---src-pages-lp-pediatric-care-coding-software-tsx": () => import("./../../../src/pages/lp/pediatric-care-coding-software.tsx" /* webpackChunkName: "component---src-pages-lp-pediatric-care-coding-software-tsx" */),
  "component---src-pages-lp-primary-care-coding-software-tsx": () => import("./../../../src/pages/lp/primary-care-coding-software.tsx" /* webpackChunkName: "component---src-pages-lp-primary-care-coding-software-tsx" */),
  "component---src-pages-lp-urgent-care-coding-software-tsx": () => import("./../../../src/pages/lp/urgent-care-coding-software.tsx" /* webpackChunkName: "component---src-pages-lp-urgent-care-coding-software-tsx" */),
  "component---src-pages-lp-whitepaper-[id]-tsx": () => import("./../../../src/pages/lp/whitepaper/[id].tsx" /* webpackChunkName: "component---src-pages-lp-whitepaper-[id]-tsx" */),
  "component---src-pages-manage-cookies-tsx": () => import("./../../../src/pages/manage-cookies.tsx" /* webpackChunkName: "component---src-pages-manage-cookies-tsx" */),
  "component---src-pages-news-id-tsx": () => import("./../../../src/pages/news/[Id].tsx" /* webpackChunkName: "component---src-pages-news-id-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-primary-care-coding-software-for-primary-care-tsx": () => import("./../../../src/pages/primary-care/coding-software-for-primary-care.tsx" /* webpackChunkName: "component---src-pages-primary-care-coding-software-for-primary-care-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-review-notification-unsubscribe-tsx": () => import("./../../../src/pages/review-notification-unsubscribe.tsx" /* webpackChunkName: "component---src-pages-review-notification-unsubscribe-tsx" */),
  "component---src-pages-securityandcompliance-tsx": () => import("./../../../src/pages/securityandcompliance.tsx" /* webpackChunkName: "component---src-pages-securityandcompliance-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-unsubscribe-tsx": () => import("./../../../src/pages/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-tsx" */),
  "component---src-pages-urgent-care-coding-software-for-urgent-care-tsx": () => import("./../../../src/pages/urgent-care/coding-software-for-urgent-care.tsx" /* webpackChunkName: "component---src-pages-urgent-care-coding-software-for-urgent-care-tsx" */),
  "component---src-pages-whitepaper-[id]-tsx": () => import("./../../../src/pages/whitepaper/[id].tsx" /* webpackChunkName: "component---src-pages-whitepaper-[id]-tsx" */),
  "component---src-pages-whitepaper-approve-tsx": () => import("./../../../src/pages/whitepaper-approve.tsx" /* webpackChunkName: "component---src-pages-whitepaper-approve-tsx" */),
  "component---src-pages-whitepaper-tsx": () => import("./../../../src/pages/whitepaper.tsx" /* webpackChunkName: "component---src-pages-whitepaper-tsx" */),
  "component---src-pages-xpertcoding-tsx": () => import("./../../../src/pages/xpertcoding.tsx" /* webpackChunkName: "component---src-pages-xpertcoding-tsx" */)
}

