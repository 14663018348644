// import React from 'react';
// import { LoaderProvider, LoaderContext } from './src/context/LoaderContext';
// import Loader from './src/components/Loader';
// import { RouteUpdateArgs } from 'gatsby';

// let setLoading: (loading: boolean) => void;

// const setLoader = (loading: boolean) => {
//   if (setLoading) {
//     setLoading(loading);
//   }
// };

// export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
//     <LoaderProvider>
//       <LoaderContext.Consumer>
//         {context => {
//           if (context && context.setLoading) { // Check if context.setLoading exists
//             setLoading = context.setLoading;
//           }
//           return (
//             <>
//               <Loader />
//               {element}
//             </>
//           );
//         }}
//       </LoaderContext.Consumer>
//     </LoaderProvider>
//   );

// export const onPreRouteUpdate = ({ location }: RouteUpdateArgs) => {
//   console.log(location, "Coming from")
//   // setLoader(true);
// };

// export const onRouteUpdate = ({ location }: RouteUpdateArgs) => {
//   console.log(location,"Let's if this worked")
//   setLoader(false);
// };

// export const onRouteUpdateDelayed = () => {
//     console.log("We should display the loader now")
//     setLoader(true);
// }


import React, { useEffect, useState } from "react";
import { LoaderProvider, LoaderContext } from "./src/context/LoaderContext";
import Loader from "./src/components/Loader";
import { ConsentProvider } from "./src/context/ConsentContext";
import { CookieModal } from "./src/components/CookiesModal";
// const CookieModal = React.lazy(() => import('./src/components/CookiesModal'));

let setLoading: (loading: boolean) => void;

const setLoader = (loading: boolean) => {
  if (setLoading) {
    setLoading(loading);
  }
};

export const wrapPageElement = ({ element, props }) => {
  const { location } = props;
  
  return wrapRootElement({ element, location });
};

export const wrapRootElement = ({ element, location }: { element: React.ReactNode, location?: Location }) => (
  <LoaderProvider>
    <LoaderContext.Consumer>
      {(context) => {
        if (context && context.setLoading) {
          setLoading = context.setLoading;
        }
        return (
          <>
            <Loader />
            <ConsentProvider>
              {element}
          
              {location && <CookieModal location={location} />}
            </ConsentProvider>
          </>
        );
      }}
    </LoaderContext.Consumer>
  </LoaderProvider>
);

export const onRouteUpdateDelayed = () => {
  setLoader(true);
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname !== prevLocation?.pathname) {
    setLoader(false);
  }
};
