

import React, { useState, useEffect } from "react";
import { LiaCookieBiteSolid } from "react-icons/lia";
import CookieConsent from "./CookieConsent";
import { useConsent, ConsentProvider } from "../../context/ConsentContext";
import "./cookies.scss";

interface CookieModalProps {
  location?: Location; 
}

export const CookieModal: React.FC<CookieModalProps> = ({ location }) => {
  // console.log(location,"2222222222222222222")
  const [animationClass, setAnimationClass] = useState("");
  const [hideCookies, setHideCookies] = useState(false);
  
  const { consent, setConsent } = useConsent();
  useEffect(() => {
    if (location && location.pathname) {
      console.log(location, "Location available");
      const excludePaths = ["/contact/", "/manage-cookies/", "/privacy-policy/"]; 
  
      const shouldHideCookies = excludePaths.some((path) => location.pathname.startsWith(path));
      setHideCookies(shouldHideCookies);
    } else {
      console.log("Location is undefined");
    }
  }, [location]);
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      const cookieBannerShown =
        localStorage.getItem("cookieBannerShown") === "false" ||
        localStorage.getItem("cookieBannerShown") === null;

      if (cookieBannerShown) {
        const handleScroll = () => {
          const scrollPosition =
            window.scrollY || document.documentElement.scrollTop;
          const scrollThreshold = window.innerHeight * 1.2;
          if (scrollPosition >= scrollThreshold) {
            setConsent((prevState) => ({
              ...prevState,
              isCookieBannerShow: true,
            }));
            setAnimationClass("visible");
            window.removeEventListener("scroll", handleScroll);
          }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    }
  }, [setConsent]);

  const handleAccept = () => {
    console.log("Cookies Accepted");
    setAnimationClass("");
    setTimeout(() => {
      setConsent((prevState) => ({
        ...prevState,
        isCookieBannerShow: false,
      }));
      localStorage.setItem("cookieBannerShown", "true");
    }, 300);
  };

  const handleDecline = () => {
    console.log("Cookies Declined");
    setAnimationClass("");
    setTimeout(() => {
      setConsent((prevState) => ({
        ...prevState,
        isCookieBannerShow: false,
      }));
      localStorage.setItem("cookieBannerShown", "true");
    }, 300);
  };

  const handleIconClick = () => {
    setConsent((prevState) => ({
      ...prevState,
      isCookieBannerShow: true,
    }));
    setTimeout(() => setAnimationClass("visible"), 0);
  };

  const handleClose = (consent: ConsentState) => {
    console.log("Save consent for these:", consent);
    setAnimationClass("");
    setTimeout(() => {
      setConsent((prevState) => ({
        ...prevState,
        isCookieBannerShow: false,
      }));
    }, 300);
  };


  return (
    <>
      {!hideCookies && (
        <>
          <div id="cookies_container" onClick={handleIconClick}>
            <div className="iconBgDiv">
              <div className="icon">
                <LiaCookieBiteSolid className="cookiesicon" />
              </div>
              <div className="iconText">Manage Cookies</div>
            </div>
          </div>
          {consent.isCookieBannerShow && (
            <div className={`cookie-modal ${animationClass}`}>
              <CookieConsent
                onAccept={handleAccept}
                onDecline={handleDecline}
                onClose={handleClose}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
