import React from 'react';
import './cookies.scss';
import { LiaCookieBiteSolid } from 'react-icons/lia';
import { IoIosClose } from 'react-icons/io';
import { FaRegCircleDown } from "react-icons/fa6";
import { useConsent } from '../../context/ConsentContext';

interface ConsentState {
  isCookieBannerShow:boolean,
  necessary: boolean;
  performance: boolean;
  targeting: boolean;
  unclassified: boolean;
}

interface CookieConsentProps {
  onAccept: (consent: ConsentState) => void;
  onDecline: () => void;
  onClose: (consent: ConsentState) => void;
}

const CookieConsent: React.FC<CookieConsentProps> = ({ onAccept, onDecline, onClose }) => {
  const { consent, setConsent } = useConsent();

  // const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, checked } = event.target;
  //   setConsent(prevState => ({
  //     ...prevState,
  //     [name]: checked,
  //   }));
  // };

  const handleAcceptAll = () => {
    const updatedConsent = {
      isCookieBannerShow:false,
      necessary: true,
      performance: true,
      targeting:true,
      unclassified:true,

    };
    setConsent(updatedConsent);
    onAccept(updatedConsent);
    if (typeof window !== 'undefined') {
      localStorage.setItem('cookieBannerShown', 'true');
    }
    window.location.reload();
  };



  // const handleSaveAndClose = () => {
  //   onClose(consent);
  //   if (typeof window !== 'undefined') {
  //     localStorage.setItem('cookieBannerShown', 'false');
  //   }
  //   window.location.reload();
  // };
  // const handleClose = () => {
  //   onClose(consent);
  //   if (typeof window !== 'undefined') {
  //     localStorage.setItem('cookieBannerShown', 'false');
  //   }
  //   // window.location.reload();
  // };

  return (
    <div className="cookie-consent">
      <div className="cookiesicon_div">
        <LiaCookieBiteSolid className="cookiesicon" />
      </div>
      <h2 className="heading">We use cookies.</h2>
      <p className="subheading">
      We use cookies to ensure that we give you the best experience on our website.<br/><a href="/cookies-policy">Read cookies policy.</a>.
      </p>

      <div className="cookie-buttons">
        <button className="primaryBtn btn" onClick={handleAcceptAll}>Accept</button>
        <a  href="/manage-cookies/" rel="noopener noreferrer" className="secondaryBtn btn" >Manage your preferences</a>
      </div>

    </div>
  );
};

export default CookieConsent;